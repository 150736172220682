<template>
    <div class="abtest">
        <h5 class="group_title">ABtest测试开关</h5>
        <div class="showrnd">
            当前随机数: <span class="curQscrnd">{{ jsbRnd }}</span>
        </div>

        <div class="h5ui-form">
            <label class="h5ui-form-label">随机数:</label>
            <input
                v-model="inputRnd"
                type="text"
                placeholder="输入0~1的随机数"
                maxlength="6"
            />
        </div>

        <button class="set-rnd" @click="generateRnd">确定修改随机数</button>
        <button @click="setNewRnd">从新生成随机数</button>
    </div>
</template>

<script>
import store from 'store';

export default {
    name: 'ABtest',
    data () {
        return {
            jsbRnd: '',
            inputRnd: '',
        }
    },
    created() {
        let jsbrnd = store.get('jsbrnd');
        this.jsbRnd = jsbrnd ? jsbrnd : '没有随机数';
    },

    methods: {
        generateRnd() {
            let regPos = /^\d+(\.\d+)?$/; //非负浮点数
            let inputRnd = this.inputRnd;
            if (!inputRnd || !regPos.test(inputRnd.trim())){
                alertMsg('输入正确的随机数', 1000);
                return;
            };
            if (inputRnd < 0 || inputRnd > 1) {
                alertMsg('输入0~1的随机数', 1000);
                return;
            };
            this.updataRndStore(inputRnd);
        },

        setNewRnd() {
            let rnd = Math.random();
            this.jsbRnd = rnd;

            this.updataRndStore(rnd);
        },

        updataRndStore(rnd) {
            store.set('jsbrnd', Number(rnd));
        }
    },
    watch: {
        inputRnd() {
            this.jsbRnd = this.inputRnd;
        }
    },
}
</script>

<style lang='less' scoped>
.abtest {
    padding: 20px 16px;
}
.group_title {
    font-size: 18px;
    color: #009bff;
    line-height: 40px;
}

.group_title {
    font-size: 18px;
    color: #009bff;
    font-weight: 500;
}

.showrnd {
    background: #FFF;
    position: relative;
    padding: 12px 0 14px 15px;
    color: #666;
    font-size: 16px;
    text-align: left;
}

label {
    text-align: left;
    width: 100px;
    padding: 12px 0 14px 15px;
    color: #666;
    line-height: 1.1333;
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    position: absolute
}

input {
    width: 100%;
    border: 0;
    outline: 0;
    font-size: 16px;
    box-shadow: none;
    -webkit-box-shadow: none;
    border-radius: 0;
    color: #333;
    padding: 10px 15px 20px 100px;
    background: #FFF;
}

button {
    display: block;
    padding: 12px 16px;
    font-size: 17px;
    margin-bottom: 0;
    font-weight: 400;
    line-height: 1;
    background: linear-gradient(90deg, #40B9FF, #009BFF);
    border: none;
    width: 100%;
    color: #fff;
    border-radius: 4px;
    box-shadow: 0 4px 30px 0 rgba(0, 155, 255, 0.4);
}

.set-rnd {
    margin-bottom: 20px;
}

</style>
